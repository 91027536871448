import { parseJson } from "../helpers/utils";

let aliPageSettings = parseJson(window.sessionStorage.getItem('pages-editor')) || [];

function getPageSettings() {
  let currentData = document.querySelector('[id="OnlineStoreEditorData"')?.innerHTML;

  if (currentData && parseJson(currentData)) {
    currentData = parseJson(currentData);
    const page = currentData.template?.basename === 'index' ? 'home' : currentData.template?.basename;

    
    page && !aliPageSettings.includes(page) && aliPageSettings.push(page);
    window.sessionStorage.setItem('pages-editor', JSON.stringify(aliPageSettings));
  }
}

function callApi() {
  fetch(`/apps/alireviews/app-block`, {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
    },
    body: JSON.stringify({
      shopify_domain: Shopify?.shop || '',
      theme_id: Shopify?.theme?.id || '',
      pages: aliPageSettings || []
    }),
  });
}

function handleSaveData() {
  getPageSettings();
  callApi();
}

handleSaveData();