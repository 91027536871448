const ar_wrap = document.querySelector('#shopify-ali-review .shop_info') || document.querySelector('[shop-name]');
const shop_id = ar_wrap && ar_wrap.getAttribute('shop-id') || null;
const shop_name = ar_wrap && ar_wrap.getAttribute('shop-name') || null;
const domain_name = ar_wrap && ar_wrap.getAttribute('domain-name') || null;

export default {
    // shop_url: process.env.MIX_ALIREVIEWS_URL || "https://" + window.location.host + "/a",
    shop_url: process.env.MIX_ALIREVIEWS_URL || "https://app.alireviews.io",
    widget_url: process.env.MIX_APP_API_URL || "https://widget.alireviews.io/api",
    widget_app_url: process.env.MIX_APP_URL || "https://widget.alireviews.io",
    report_url: process.env.MIX_REPORT_API_URL || "https://report-api.alireviews.io/api",
    report_key: process.env.MIX_REPORT_PUBLIC_KEY || "OemtTQU6IJH8LCmg6O06SlMF2RAU5r",
    shop_id: shop_id,
    shop_name: shop_name,
    domain_name: domain_name,
    app_cdn_url: process.env.APP_CDN_URL || "https://cdnhub.alireviews.io"
}
